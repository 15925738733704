.public-layout .public-account-header__image,
.hero-widget__img {
  height: auto !important;
  min-height: 100px;
  max-height: 1000px;
}

.public-layout .public-account-header__image img[src="/headers/original/missing.png"] {
  height: 360px;
}

.logo-container h1 img,
.landing-page .brand img {
  height: 60px;
}

.simple_form .actions {
  flex-direction: column;
  .g-recaptcha {
    margin: auto;
    margin-bottom: 15px;
  }
}

.ui__header__links .button.bottom_right {
  position: fixed;
  right: 18px;
  bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  font-size: 24px;
  border-radius: 50%;
}

.compose-form__submit > button {
  padding: 7px 50%;
}
